import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Section, SectionTitle, VerticalSpacer, Phonetics } from '../';
import Icon from '@mdi/react';
import { mdiMapMarker, mdiEmail, mdiCellphone } from '@mdi/js';
import './Contact.scss';

class Contact extends React.Component {
    render() {
        return (
            <Section id='contact' className='bg-is-grey flat'>
                <SectionTitle title='Contact' skin='dark' direction='down' />
                <Phonetics>
                    <p>
                        [ <b>kon</b>-takt ]
                    </p>
                </Phonetics>
                <Container>
                    <Row>
                        <Col className='text-center text-md-right'>
                            <h1>Contact</h1>
                        </Col>
                    </Row>

                    <VerticalSpacer height={100} />

                    <Row>
                        <Col className='pb-5 pb-md-0' xs={12} md={4}>
                            <p className='icon-w-text'>
                                <Icon path={mdiCellphone} size={'24px'} /> +27
                                084 207-5542
                            </p>
                        </Col>
                        <Col className='pb-5 pb-md-0' xs={12} md={4}>
                            <p className='icon-w-text'>
                                <Icon path={mdiEmail} size={'24px'} />{' '}
                                neil@neilcoetzer.com
                            </p>
                        </Col>
                        <Col className='pb-5 pb-md-0' xs={12} md={4}>
                            <p className='icon-w-text'>
                                <Icon path={mdiMapMarker} /> 5 Mt Saddlesback
                                Street, Midstream, Johannesburg, Gauteng, South
                                Africa, 1692
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Section>
        );
    }
}

export default Contact;
