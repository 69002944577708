import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import './TimelineEntry.scss';

class TimelineEntry extends React.Component {
    static propTypes = {
        details: PropTypes.shape( {
            dates: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            company: PropTypes.string.isRequired,
            logo: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            tech: PropTypes.string.isRequired,
        } ),
        index: PropTypes.string.isRequired,
    };
    render() {
        const {
            dates,
            title,
            company,
            logo,
            description,
            tech,
        } = this.props.details;
        const { index } = this.props;

        return (
            <Col
                xs={ 12 }
                md={ { span: 6, offset: index % 2 ? 6 : 0 } }
                className='px-0'>
                <div
                    className={ `timeline-entry is-${ index % 2 ? 'right' : 'left'
                        }` }>
                    <div className='content'>
                        <h6 className='dates'>{ dates }</h6>
                        <p className='title'>
                            { title } at { company }
                        </p>
                        <div className='description'>
                            <p>{ description }</p>
                            <p>
                                <b>Tools & tech: </b>
                                { tech }
                            </p>
                        </div>
                    </div>
                    <img
                        className='some'
                        src={ process.env.PUBLIC_URL + logo }
                        alt=''
                    />
                </div>
            </Col>
        );
    }
}

export default TimelineEntry;
