import React from 'react';
import './NavBar.scss';
import { Button } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiClose, mdiMenu } from '@mdi/js';
import logo from '../assets/logo.svg';

class NavBar extends React.Component {
    state = {
        top: true,
        mobileMenu: false,
    };

    static propTypes = {};

    openMobileMenu = () => {
        this.setState({
            mobileMenu: true,
        });
    };

    closeMobileMenu = () => {
        this.setState({
            mobileMenu: false,
        });
    };

    componentDidMount() {
        window.onscroll = () => {
            if (window.pageYOffset === 0) {
                // alert('I AM AT THE TOP');
                this.setState({ top: true });
            } else {
                this.setState({ top: false });
            }
        };
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    render() {
        const { top, mobileMenu } = this.state;
        return (
            <>
                <div className='nlogo'>
                    <img src={logo} alt='' />
                </div>
                <div className={`navbar ${top ? 'at-top' : null}`}>
                    <Icon
                        path={mdiMenu}
                        size={'24px'}
                        className='menu-toggle d-md-none'
                        onClick={this.openMobileMenu}
                    />
                    <div
                        className={`navitems d-none d-md-block ${
                            mobileMenu ? 'is-mobile' : null
                        }`}>
                        <Icon
                            path={mdiClose}
                            size={'24px'}
                            className='d-md-none menu-close'
                            onClick={this.closeMobileMenu}
                        />
                        <Button href='#hero' onClick={this.closeMobileMenu}>
                            Home
                        </Button>
                        <Button href='#about-me' onClick={this.closeMobileMenu}>
                            About me
                        </Button>
                        <Button href='#skills' onClick={this.closeMobileMenu}>
                            Skills
                        </Button>
                        <Button
                            href='#experience'
                            onClick={this.closeMobileMenu}>
                            Experience
                        </Button>
                        <Button
                            href='#education'
                            onClick={this.closeMobileMenu}>
                            Education
                        </Button>
                        <Button
                            href='#references'
                            onClick={this.closeMobileMenu}>
                            References
                        </Button>
                        <Button href='#contact' onClick={this.closeMobileMenu}>
                            Contact
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}

export default NavBar;
