import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import './CustomButton.scss';

class CustomButton extends React.Component {
    static propTypes = {
        skin: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        external: PropTypes.bool,
    };

    render() {
        const { skin, text, link, external, onClick } = this.props;
        return (
            <Button
                href={link}
                className={`is-${skin}`}
                target={external ? '_blank' : '_self'}
                onClick={onClick ? onClick : null}>
                {text}
            </Button>
        );
    }
}

export default CustomButton;
