import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import {
    Section,
    SectionTitle,
    ReferenceBlock,
    VerticalSpacer,
    Phonetics,
} from '..';

class References extends React.Component {
    static propTypes = {
        references: PropTypes.arrayOf(
            PropTypes.shape( {
                logo: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                company: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            } ),
        ),
    };
    render() {
        const { references } = this.props;
        return (
            <Section id='references' className='bg-is-black'>
                <SectionTitle
                    title='References'
                    skin='light'
                    direction='down'
                />
                <Phonetics>
                    <p>
                        [ <b>ref</b>-er-<i>uh</i>ns, <b>ref</b>-r<i>uh</i>ns ]
                    </p>
                </Phonetics>
                <Container>
                    <Row>
                        <Col className='text-is-light text-center text-md-left'>
                            <h1>
                                Refer
                                <br />
                                ences
                            </h1>
                        </Col>
                    </Row>

                    <VerticalSpacer height={ 100 } />

                    <Row>
                        { Object.keys( references ).map( key => (
                            <Col key={ key } xs={ 12 } md={ 6 } lg={ 4 }>
                                <ReferenceBlock details={ references[ key ] } />
                            </Col>
                        ) ) }
                    </Row>
                </Container>
            </Section>
        );
    }
}

export default References;
