import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import {
    Section,
    SectionTitle,
    VerticalSpacer,
    EducationCard,
    Phonetics,
} from '..';

class Education extends React.Component {
    static propTypes = {
        education: PropTypes.arrayOf(
            PropTypes.shape({
                completed: PropTypes.bool,
                logo: PropTypes.string,
                dates: PropTypes.string,
                title: PropTypes.string,
                description: PropTypes.string,
            }),
        ),
    };

    componentDidMount() {
        window.setTimeout(window.initOwl, 2000);
    }

    render() {
        const { education } = this.props;
        return (
            <Section id='education' className='bg-is-teal'>
                <SectionTitle title='Education' skin='dark' direction='up' />
                <Phonetics>
                    <p>
                        [ ej-<i>oo</i>-<b>key</b>-sh<i>uh</i>n ]
                    </p>
                </Phonetics>
                <Container>
                    <Row>
                        <Col className='text-center text-md-right'>
                            <h1>
                                Edu
                                <br />
                                cation
                            </h1>
                        </Col>
                    </Row>
                </Container>

                <VerticalSpacer height={60} />
                <div id='edu-slider' className='owl-carousel'>
                    {Object.keys(education).map(key => (
                        <EducationCard key={key} details={education[key]} />
                    ))}
                </div>
                <VerticalSpacer height={100} />
            </Section>
        );
    }
}

export default Education;
