import React from 'react';
import PropTypes from 'prop-types';
import './ReferenceBlock.scss';
// import Icon from '@mdi/react';
// import { mdiCellphone, mdiEmail } from '@mdi/js';

class ReferenceBlock extends React.Component {
    static propTypes = {
        details: PropTypes.shape( {
            logo: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            company: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        } ),
    };
    render() {
        const { details } = this.props;
        return (
            <div className='reference-block text-is-light'>
                <div className='logo'>
                    <img src={ details.logo } alt='' />
                </div>
                <h6 className='name'>{ details.name }</h6>
                <p className='company'>{ details.company }</p>
                <p className='title'>{ details.title }</p>
            </div>
        );
    }
}

export default ReferenceBlock;
