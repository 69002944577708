import React from 'react';
import PropTypes from 'prop-types';
import './EducationCard.scss';

class EducationCard extends React.Component {
    static propTypes = {
        details: PropTypes.shape({
            completed: PropTypes.bool.isRequired,
            logo: PropTypes.string.isRequired,
            dates: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }),
    };

    render() {
        const { details } = this.props;
        return (
            <div className='education-card text-is-light'>
                <div className='tag'>
                    {details.completed ? 'completed' : 'enrolled'}
                </div>
                <div className='logo'>
                    <img src={details.logo} alt='' />
                </div>
                <h6 className='dates'>{details.dates}</h6>
                <p className='title'>
                    <b>{details.title}</b>
                </p>
                <p className='description'>{details.description}</p>
            </div>
        );
    }
}

export default EducationCard;
