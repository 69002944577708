import React from 'react';
import PropTypes from 'prop-types';
import './Phonetics.scss';

const Phonetics = props => (
    <div className={`phonetics is-${props.skin ? props.skin : 'light'}`}>
        {props.children}
    </div>
);

Phonetics.propTypes = {
    skin: PropTypes.string,
    children: PropTypes.object,
};

export default Phonetics;
