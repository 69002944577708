import React from 'react';
import './AboutMe.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Section, SectionTitle, Phonetics } from '..';

const AboutMe = () => (
    <Section
        id='about-me'
        className='bg-is-black-gradient d-flex flex-row justify-content-center align-items-center'>
        <SectionTitle title='About me' skin='light' direction='down' />
        <Phonetics>
            <p>
                [ <i>uh</i>-<b>bout</b> ] [ mee ]
            </p>
        </Phonetics>
        <Container>
            <Row className='d-flex flex-row flex-wrap justify-content-start align-items-center'>
                <Col
                    sm={12}
                    md={6}
                    lg={{ span: 5, offset: 1 }}
                    className='text-is-light text-center text-md-right'>
                    <h1 className='mb-5 mb-md-0'>
                        About
                        <br />
                        me
                    </h1>
                </Col>
                <Col
                    sm={12}
                    md={6}
                    lg={4}
                    className='text-is-light text-center text-md-left'>
                    <p>
                        I am a UX/UI designer & front-end web developer with
                        over 10 years experience, skilled in Adobe CC Suite,
                        HTML, CSS3, ReactJS, JSON, AJAX, PHP, jQuery, Sass and
                        Wordpress. Having worked on diverse clientele, I’ve
                        gained experience throughout all stages of the design
                        and development process. Blending these experiences
                        together helped me to have an enhanced understanding of
                        interactive design, allowing me to bridge the gap
                        between good design and development (design for code).
                    </p>

                    <p>
                        For the last 7 years I have worked remotely as a
                        freelancer and have learned how to apply my time more
                        efficiently to be able to deliver more effectively,
                        while still maintaining a good work-life balance.
                    </p>

                    <p>
                        I’m currently working remotely as a strategic partner
                        and technical specialist for Plus Narrative, an
                        end-to-end digital agency based in both Johannesburg and
                        Durban, South Africa as well as the owner of Gapshift
                        which is an independent design and development
                        consultancy.
                    </p>

                    <p>
                        After studying at North-West University in South Africa
                        for 5 years, I earned my Bachelor of Arts degree in
                        Graphic Design.
                    </p>

                    <p>
                        Outside of work, I love the outdoors, camping and
                        treasure the importance of spending time with family.
                        I’m an avid runner and cyclist, keeping my mind and
                        body healthy all the time.
                    </p>
                </Col>
            </Row>
        </Container>
    </Section>
);

export default AboutMe;
