import React from 'react';
import PropTypes from 'prop-types';
import '../theme/Theme.scss';
import base from '../base';
import { analytics } from '../base';
import {
    Hero,
    AboutMe,
    Skills,
    Experience,
    Education,
    References,
    Contact,
    CallToAction,
    NavBar,
} from '../components';

class App extends React.Component {
    state = {
        experiences: [],
        references: [],
        education: [],
    };

    static propTypes = {
        experiences: PropTypes.array,
        references: PropTypes.array,
        education: PropTypes.array,
    };

    logResumeDownload = () => {
        analytics.logEvent( 'resume_download', {
            content_type: 'document',
            item_id: 'Resume download',
        } );
        // console.log('log_event_resume');
    };
    logPortfolioView = () => {
        analytics.logEvent( 'portfolio_view', {
            content_type: 'link',
            item_id: 'Portfolio',
        } );
        // console.log('log_event_portfolio');
    };

    componentDidMount() {
        this.expRef = base.bindCollection( 'experiences', {
            context: this,
            state: 'experiences',
        } );
        this.refRef = base.bindCollection( 'references', {
            context: this,
            state: 'references',
            query: ref => {
                return ref.orderBy( "id", "desc" );
            }
        } );
        this.eduRef = base.bindCollection( 'education', {
            context: this,
            state: 'education',
        } );
    }

    componentWillUnmount() {
        base.removeBinding( this.expRef );
        base.removeBinding( this.refRef );
        base.removeBinding( this.eduRef );
    }

    render() {
        return (
            <>
                {/* NavBar */ }
                <NavBar />

                {/* Hero */ }
                <Hero />

                {/* AboutMe */ }
                <AboutMe />

                {/* Skills */ }
                <Skills />

                {/* Experience */ }
                <Experience experiences={ this.state.experiences } />

                {/* Education */ }
                <Education education={ this.state.education } />

                {/* References */ }
                <References references={ this.state.references } />

                {/* CallToAction */ }
                <CallToAction
                    logResumeDownload={ this.logResumeDownload }
                    logPortfolioView={ this.logPortfolioView }
                />

                {/* Contact */ }
                <Contact />
            </>
        );
    }
}

export default App;
