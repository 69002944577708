import React from 'react';
import PropTypes from 'prop-types';
import './Skill.scss';

class Skill extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
    };
    render() {
        const { title, rating } = this.props;
        const dots = [];
        for (var i = 0; i < 5; i++) {
            if (i <= rating - 1) {
                dots.push(<div key={i} className='dot active'></div>);
            } else {
                dots.push(<div key={i} className='dot'></div>);
            }
        }

        return (
            <div className='skill'>
                <div className='title'>{title}</div>
                <div className='rating'>{dots}</div>
            </div>
        );
    }
}

export default Skill;
