import React from 'react';
import PropTypes from 'prop-types';
import './VerticalSpacer.scss';

const VerticalSpacer = props => (
    <div className='vertical-spacer' style={{ height: `${props.height}px` }} />
);

VerticalSpacer.propTypes = {
    height: PropTypes.number.isRequired,
};

export default VerticalSpacer;
