import React from 'react';
import { Section, Skill, SectionTitle, Phonetics } from '..';
import { Container, Row, Col } from 'react-bootstrap';

class Skills extends React.Component {
    render() {
        return (
            <Section id='skills' className='bg-is-grey flat'>
                <SectionTitle title='Skills' skin='dark' direction='up' />
                <Phonetics>
                    <p>[ skill ]</p>
                </Phonetics>
                <Container>
                    <Row>
                        <Col
                            xs={ { span: 8, offset: 3 } }
                            md={ { span: 3 } }
                            lg={ { span: 4, offset: 2 } }
                            xl={ { span: 4, offset: 1 } }>
                            <Skill title={ 'HTML5' } rating={ 5 } />
                            <Skill title='PostCSS' rating={ 5 } />
                            <Skill title='SASS' rating={ 5 } />
                            <Skill title='Javascript' rating={ 3 } />
                            <Skill title='PHP' rating={ 3 } />
                            <Skill title='Wordpress' rating={ 5 } />
                            <Skill title='Reactjs' rating={ 4 } />
                        </Col>
                        <Col
                            xs={ { span: 8, offset: 3 } }
                            md={ { span: 3, offset: 2 } }
                            lg={ { span: 4, offset: 0 } }>
                            <Skill title='Git' rating={ 5 } />
                            <Skill title='UX/UI' rating={ 5 } />
                            <Skill title='XD' rating={ 5 } />
                            <Skill title='Photoshop' rating={ 5 } />
                            <Skill title='Sketch' rating={ 3 } />
                            <Skill title='After Effects' rating={ 3 } />
                            <Skill title='Illustrator' rating={ 5 } />
                        </Col>
                    </Row>
                </Container>
            </Section>
        );
    }
}

export default Skills;
