import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Section, SectionTitle, CustomButton, Phonetics } from '../';

class CallToAction extends React.Component {
    render() {
        const { logPortfolioView } = this.props;
        return (
            <Section id='cta' className='bg-is-teal flat'>
                <SectionTitle title='Download' skin='dark' direction='up' />
                <Phonetics>
                    <p>
                        [ <b>doun</b>-lohd ]
                    </p>
                </Phonetics>
                <Container>
                    <Row>
                        <Col className='text-center'>
                            <CustomButton
                                link='https://www.behance.net/GapshiftAgency'
                                text='View my work'
                                skin='purple'
                                external={ true }
                                onClick={ logPortfolioView }
                            />
                        </Col>
                    </Row>
                </Container>
            </Section>
        );
    }
}

export default CallToAction;
