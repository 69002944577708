import React from 'react';
import PropTypes from 'prop-types';
import './SectionTitle.scss';

const SectionTitle = props => (
    <div className={`section-title is-${props.direction}`}>
        <div className={`stripe is-${props.skin}`} />
        <div className={`title is-${props.skin}`}>{props.title}</div>
    </div>
);

SectionTitle.propTypes = {
    direction: PropTypes.string.isRequired,
    skin: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default SectionTitle;
