import Rebase from 're-base';
import firebase from '@firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
var firebaseApp = firebase.initializeApp({
    apiKey: 'AIzaSyARmg0M7i9sJWQiujUPzcP_SvX_6e3R1C8',
    authDomain: 'my-resume-b2f9a.firebaseapp.com',
    databaseURL: 'https://my-resume-b2f9a.firebaseio.com',
    projectId: 'my-resume-b2f9a',
    storageBucket: 'my-resume-b2f9a.appspot.com',
    messagingSenderId: '625153735377',
    appId: '1:625153735377:web:08200c22a49103512823f0',
    measurementId: 'G-DPYXM5B8TS',
});
var analytics = firebase.analytics();

var db = firebase.firestore(firebaseApp);

var base = Rebase.createClass(db);

// This is a named export
export { firebaseApp, analytics };

// This is a default export
export default base;
