import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import {
    Section,
    SectionTitle,
    TimelineEntry,
    VerticalSpacer,
    Phonetics,
} from '..';
import './Experience.scss';

class Experience extends React.Component {
    static propTypes = {
        experiences: PropTypes.arrayOf(
            PropTypes.shape({
                company: PropTypes.string.isRequired,
                dates: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                logo: PropTypes.string.isRequired,
                tech: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            }),
        ),
    };
    render() {
        const { experiences } = this.props;
        return (
            <Section id='experience' className='bg-is-purple flat'>
                <SectionTitle title='Experience' skin='dark' direction='down' />
                <Phonetics skin='dark'>
                    <p>
                        [ ik-<b>speer</b>-ee-<i>uh</i>ns ]
                    </p>
                </Phonetics>
                <Container>
                    <Row>
                        <Col className='text-center text-md-left'>
                            <h1>
                                Expe
                                <br />
                                rience
                            </h1>
                        </Col>
                    </Row>
                    <VerticalSpacer height={100} />
                    <Row>
                        <Col className='timeline'>
                            <div className='line' />
                            {Object.keys(experiences).map(key => (
                                <TimelineEntry
                                    key={key}
                                    index={key}
                                    details={experiences[key]}
                                />
                            ))}
                        </Col>
                    </Row>
                </Container>
            </Section>
        );
    }
}

export default Experience;
