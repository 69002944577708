import React from 'react';
import PropTypes from 'prop-types';
import './Section.scss';

const Section = props => (
    <section id={props.id} className={props.className}>
        {props.children}
    </section>
);

Section.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.array.isRequired,
};

export default Section;
