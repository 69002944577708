import React from 'react';
import './Hero.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Section, SectionTitle } from '..';
import NameIntro from '../../assets/name-intro.svg';

const Hero = () => (
    <Section
        id='hero'
        className='bg-is-red pb-0 d-flex flex-row justify-content-center align-items-sm-start align-items-lg-center'>
        <SectionTitle title='Home' skin='dark' direction='up' />
        <Container>
            <Row>
                <Col
                    sm={12}
                    lg={6}
                    className='d-flex flex-row justify-content-center align-items-center'>
                    <img src={NameIntro} alt='' />
                </Col>
            </Row>
        </Container>
        <div className='myself' />
    </Section>
);

export default Hero;
